

import { firestore } from '@/firebase';
import { ROOT_ACTIONS } from '@/store/actions';
import { USER_GETTERS } from '@/store/user/getters';
import { Phase } from '@/types';
import { collection, getDoc, getDocs, orderBy, query, where, doc, addDoc, FieldPath } from 'firebase/firestore';
import { Component, Prop, Vue, Watch, } from 'vue-property-decorator';


@Component
export default class ProgramScreenCastsCard extends Vue {
    @Prop({ required: true }) userId!: string;
    @Prop({ required: true }) phases!: Phase[];

    screencasts = [] as any[];


    get currentUser() {
        return this.$store.getters[USER_GETTERS.ACTING_USER];
    }

    open = false;

    async fetchScreenCasts() {
        this.screencasts = [];
        const result = await getDocs(query(collection(firestore, 'content-assignments'), where('user', '==', this.userId)));
        for (const assignment of result.docs) {
            // get doc.data().contentId
            if (!assignment.data().contentId) {
                continue;
            }
            const content = await getDoc(doc(firestore, 'content-items', assignment.data().contentId));
            this.screencasts.push({
                id: assignment.id,
                title: content.data()!.title,
                completed: assignment.data().completed,
            })
        }
    }

    async mounted() {
        await this.fetchScreenCasts();
    }

    @Watch('phases', { immediate: true })
    async onPhasesChange() {
        for (const phase of this.phases) {
            const start = phase.start.toDate();
            const end = phase.end.toDate();
            const now = new Date();
            if (now >= start && now <= end) {
                this.selectedPhase = phase.id!;
                break;
            }
        }
    }

    addContentAssignmentDialog = false;

    content = [] as any[];
    selectedContent = '';

    selectedPhase = '';

    @Watch('addContentAssignmentDialog')
    async onOpenAddContentAssignmentDialog() {
        if (this.addContentAssignmentDialog) {
            const docs = await getDocs(query(collection(firestore, 'content-items')));
            this.content = docs.docs.map(doc => {
                return {
                    id: doc.id,
                    title: doc.data().title,
                    language: doc.data().language,
                }
            });
        }
    }

    async addContentAssignment() {
        if (!this.selectedContent || !this.selectedPhase) {
            this.$store.dispatch(ROOT_ACTIONS.TOGGLE_NOTIFICATION, {
                color: 'error',
                text: 'Please select a screencast and a phase',
            })
            return;
        }
        await addDoc(collection(firestore, 'content-assignments'), {
            user: this.userId,
            contentId: this.selectedContent,
            completed: false,
            requestedBy: this.currentUser.id,
            phase: this.selectedPhase,
        });
        this.addContentAssignmentDialog = false;
        await this.fetchScreenCasts();
    }
}
